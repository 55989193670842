@import "../../variables.scss";

.desktop-map {
    display: flex;
    flex-direction: column;
    color: $light-text-color;

    &__container {
        display: flex;
        height: 100vh;
        flex-direction: column;
        background: url("/images/tree.jpeg");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 50% 65%;
    }

    &__content_wrapper {
        display: flex;
        flex: 1;
        align-items: center;
        flex-direction: column;
    }

    &__map_wrapper {
        display: flex;
        flex: 1;
        border-radius: 5px;
        aspect-ratio: 2.1;
        margin-bottom: 20px;
        max-width: 100%;
    }

    &__map {
        flex: 1;
        border-radius: 5px;
        clip-path: view-box;
    }

    &__legend {
        position: absolute;
        width: 0.5 * 456px;
        height: 0.5 * 524px;
        bottom: 0;
        margin-bottom: 20px + 8px;
        margin-left: 8px;
        border-radius: 5px;
        background: url("/images/legend.png");
        background-size: cover;
        box-shadow: 0 3px 14px rgba(0, 0, 0, 0.4);
    }

    &__legend_button {
        position: absolute;
        background-color: #FF7100;
        bottom: 0;
        margin-bottom: 20px + 8px;
        margin-left: 8px;
        padding: 10px;
        aspect-ratio: 1;
        align-content: center;
        text-transform: uppercase;
        font-weight: bold;
        border-radius: 5px;
        font-size: $small-font-size;
    }
}

.mobile-map {
    display: flex;
    flex: 1;
    background: url("/images/tree.jpeg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 65%;

    &__container {
        margin: 10px;
        margin-top: 20px;
    }

    &__map {
        width: 100%;
        border-radius: 2px;
    }
}